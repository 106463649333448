import { useNavItem } from "@restart/ui/esm/NavItem";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { headers } from "../header";
import { Toaster } from 'react-hot-toast';
import notify from "../helper/toast";
import LoadingOverlay from "react-loading-overlay";

function Signup(){

    const [phone, setPhone] = useState("");
    const [fullname, setFullname] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();



    const handleSignup = () => {
        if(fullname === ""){
            notify("Please fill all the fields");
        }
        else{
            setLoading(true);
            console.log(phone);
            fetch(process.env.REACT_APP_BASE_URL+"auth/create-user", {
                method: "POST",
                headers: headers,
                    body: JSON.stringify({
                        phone: phone,
                        fullname:fullname
                        })
                    })
                    .then((data) => {
                        setLoading(false);
                        if(data.status !== 200){
                            notify("User alreary exists, please login");
                        }
                        else{
                            notify("Account created successfully, Login to continue :)");
                            navigate("/login");
                        }
            })
        }
        
        
    }
    
    return (
        <div className="login-container">
            <LoadingOverlay active={loading} spinner>
                <Toaster />
                <div class="login-card">
                    <img src="https://www.homeradius.in/assets/brand/logo-full.svg" class="mb-4 w-100" alt="logo" style={{height:30, width:230}}></img>
                    <div class="col-md-12 mb-3">
                        <input type="fullname" class="form-control disabled" placeholder="Fullname" onChange={(e)=>{setFullname(e.target.value)}}/>
                    </div>
                    <div class="col-md-12 mb-3">
                        <input type="phone" class="form-control disabled" placeholder="Mobile number" onChange={(e)=>{setPhone(e.target.value)}}/>
                    </div>
                    <button class="btn btn-primary w-100 mb-3" onClick={handleSignup}>Create account</button>
                    <button class="btn btn-primary-link w-100 mt-3" onClick={()=>{navigate("/login")}}>Already have an account? <b>Login</b></button>
                </div>
            </LoadingOverlay>
        </div>
    );
}
export default Signup;