import { useState } from 'react';
import Calendar from 'moedim';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Chip } from '@mui/material';
import { motion } from "framer-motion";
function DateTimeLayout(props){
    const og_timeRanges = [
        {start: '7', text:'Before 8 AM'},
        {start: '8', text:'8 AM - 10 AM'},
        {start: '10', text:'10 AM - 12 PM'},
        {start: '12', text:'12 PM - 2 PM'},
        {start: '14', text:'2 PM - 4 PM'},
        {start: '16', text:'4 PM - 6 PM'},
        {start: '18', text:'After 6 PM'},
    ];
    const [timeRanges, setTimeRanges] = useState(og_timeRanges);
    const days = ['Today', 'Tomorrow', 'Day after'];
    const [selectedDay, setSelectedDay] = useState(''); // days[0
    const [date,setDate] = useState(props.selectedDate);
    const [time,setTime] = useState(props.selectedTime);
    const getDateAfterDays = (days) => {
        setTime('');
        //Today Formatted Date
        let currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // January is 0
        const year = currentDate.getFullYear();
        const today_date = `${day}/${month}/${year}`;
        //Add days to current date
        currentDate.setDate(currentDate.getDate() + days);
        const day1 = String(currentDate.getDate()).padStart(2, '0');
        const month1 = String(currentDate.getMonth() + 1).padStart(2, '0'); // January is 0
        const year1 = currentDate.getFullYear();
        const added_date = `${day1}/${month1}/${year1}`;
        //If date is today then update time ranges
        if(added_date === today_date){
            let date = new Date();
            const current_hour = date.getHours();
            console.log(current_hour);
            const updaed_time_ranges = timeRanges.filter((time_range)=>{
                return parseInt(time_range.start) > current_hour;
            })
            setTimeRanges(updaed_time_ranges);
        }
        else{
            setTimeRanges(og_timeRanges);
        }
        console.log(added_date);

        return added_date;
    }

    return(
        <motion.div
            initial={{ opacity: 0, x:-100}}
            animate={{ opacity: 1, x:0 }}
            exit={{ opacity: 0, x:-100 }}
            transition={{ duration: 0.5 }}
        >
            <p className="sub-title">Select date and time</p>
            <p className="">Date</p>
            {days.map((option,index)=>{
                return(<Chip className="m-1" label={option} onClick={()=>{setDate(getDateAfterDays(index)); setSelectedDay(option)}}  color={option === selectedDay ? 'primary' : 'default'}/>)
            })}
            <p className="mt-3">Time</p>
            {timeRanges.map((option,index)=>{
                return(<Chip className="m-1" label={option.text} onClick={()=>{setTime(option.text)}}  color={option.text === time ? 'primary' : 'default'}/>)
            })}
            
            <div className="d-flex w-100 justify-content-end mt-3">
                <button className="btn btn-primary px-5" onClick={()=>{
                    props.onNext({'date':date,'time':time})
                }}>Next</button>
            </div>
        </motion.div>
    )
}

export default DateTimeLayout;
