import { useState } from "react";
import { Radio, RadioGroup } from "react-radio-group";
import { Chip } from '@mui/material';
import { motion } from "framer-motion";


function TimeLayout(props){
    const [time,setTime] = useState(props.selectedTime);
    const options = ['Before 8 AM','8 AM -10 AM','10 AM - 12 PM','12 PM - 2 PM','2 PM - 4 PM','4 PM - 6 PM','After 6 PM','Anytime']
    return(
        <motion.div
            initial={{ opacity: 0, x:-100}}
            animate={{ opacity: 1, x:0 }}
            exit={{ opacity: 0, x:-100 }}
            transition={{ duration: 0.5 }}
        >
            <p className="sub-title mb-3">Required time</p>
            {options.map((option,index)=>{
                return(<Chip className="m-1" label={option} onClick={()=>{setTime(option)}}  color={option === time ? 'primary' : 'default'}/>)
            })}
            <div className="d-flex w-100 justify-content-end mt-3">
                <button className="btn btn-primary px-5" onClick={()=>{props.onNext(time)}}>Next</button>
            </div>
        </motion.div>
    )
}

export default TimeLayout;