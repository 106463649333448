function FinalAmountActionModel(props){
    return (
        <>      
            {
                props.isVisible?(
                    <div className="modal-backdrop">
                        <div className="modal-container">
                            <div className="row g-4">
                                <div className="col-12">
                                    <p className="title">Professional has updated the final amount as ₹500 for this enquiry</p>
                                </div>
                                <div className="col-6">
                                    <button className="btn btn-primary w-100" onClick={props.onReject}>Reject</button>
                                </div>
                                <div className="col-6">
                                    <button className="btn btn-secondary w-100" onClick={props.onAccept}>Accept</button>
                                </div>
                            </div>
                        </div>
                    </div>
                ):null
            }
        </>
        
    )
}
export default FinalAmountActionModel;