import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import BackButton from '../components/Buttons/BackButton';
import PageHadler from '../components/NewEnquiryLayout/PageHandler';

function NewEnquiry(){
    const[page,setPage] = useState(1);
    const navigate = useNavigate();
    const state =  useLocation().state;
    const nextPage = ()=>{
        setPage(page+1)
    }
    const prevPage = ()=>{
        if(page===1){
            navigate(-1);
        }
        else{
            setPage(page-1);
        }
    }
    return(
        <div>
            <div className="container">
                <BackButton onClick={prevPage}/>
                <PageHadler currentPage={page} handleNext={nextPage} handlePrev={prevPage} selectedService={state.service_id}/>
            </div>
        </div>
    )

}

export default NewEnquiry;