function Spinner(props){
    if(props.visible){
        return(
            <div className="d-flex justify-content-center align-items-center" style={{height:'100%',width:'100%', position:'fixed',zIndex:'99999',top:0,left:0, backgroundColor:'#b2acaca1'}}>
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        ) 
    }
    else{
        return null
    }
}

export default Spinner;