import React, { useState } from "react"
import { motion } from "framer-motion";

function DescriptionLayout(props){
    const [enquiry, setEnquiry] = useState(props.selectedDesription)
    return(
        <motion.div
            initial={{ opacity: 0, x:-100}}
            animate={{ opacity: 1, x:0 }}
            exit={{ opacity: 0, x:-100 }}
            transition={{ duration: 0.5 }}
        >
            <p className="sub-title">Your requirement</p>

            <textarea className="form-control mb-3 my" placeholder="Describe your requirement" style={{height:250}} value={props.selectedDesription} onChange={(e)=>{setEnquiry(e.target.value)}}></textarea>
            <div className="d-flex w-100 justify-content-end mb-3">
                <button className="btn btn-primary px-5" onClick={()=>{props.onNext(enquiry)}}>Next</button>
            </div>
           
        </motion.div>
    )
}

export default DescriptionLayout;