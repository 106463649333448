import Offcanvas from 'react-bootstrap/Offcanvas';
import ReactStars from 'react-rating-stars-component';
import InfoCard from './InfoCard';
import Review from './Review';
import Service from './Service';
function ProfessionalInfo(props){
    const professionalInfo = props.data;
    const professionalShow = props.show;
    const setProfessionalShow = props.setShow;
    return (
    <Offcanvas show={professionalShow} onHide={()=>{setProfessionalShow(false)}}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Professional info</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {professionalInfo !=null ? (
                        <>
                        <div class="row g-3 g-flex align-items-center mb-3">
                            <div class="col-md-auto">  
                                <img src={professionalInfo.professional_info.dp} class="rounded-circle" alt="profile" style={{height:70, width:70}}></img>
                            </div>
                            <div class="col-md-auto">
                                <div class="col-md-auto d-flex align-items-center">
                                    <p class="sub-title m-0">{professionalInfo.professional_info.fullname} &nbsp;</p><img src={professionalInfo.professional_info.verified==="true"?"https://homeradius.in/assets/brand/certified.png":"https://homeradius.in/assets/brand/certified.png"} style={{height:25, objectFit:"cover"}}></img>
                                </div>
                                <div class="row">
                                    <div class="col-md-auto d-flex align-items-center">
                                        <p class="m-0 p-0">{professionalInfo.professional_info.completed_works} completion(s)</p>
                                    </div>
                                    <div class="col-md-auto">
                                        <ReactStars
                                            count={5}
                                            size={24}
                                            value={professionalInfo.professional_info.rating}
                                            activeColor="#ffd700"
                                            edit={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <p>Offered services</p>
                            {professionalInfo.professional_services.map((service)=>{
                                return(
                                    <div class="col-md-4">
                                        <Service data={service} selected={null}/>
                                    </div>
                                )
                            })}
                        </div>
                        <div class="row mb-3 g-4">
                            <p>Reviews</p>
                            {professionalInfo.professional_ratings.map((review)=>{
                                return (
                                    <div class="col-md-12">
                                        <Review data={review}/>
                                    </div>
                                )
                            })}
                        </div>    

                        
                        </>
                    ) : (
                        <></>
                    ) }

                </Offcanvas.Body>
            </Offcanvas>
    )
}

export default ProfessionalInfo;