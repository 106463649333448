import { MdOutlineNotes } from "react-icons/md";
import {MdOutlineCurrencyRupee, MdOutlineDateRange, MdAvTimer} from "react-icons/md";

function Quotation(props){
    return (
        <div>
            {props.data!==null?(
            <div class="card bg-hr p-3 border-none mb-3" style={{background:'#212529', color:'#fff', height:'auto'}}>
                <div class="card-body">
                    <div class="row g-4 d-flex mb-3" >
                        <div class="col-auto" onClick={()=>{props.onProfessionalClick(props.data.professional_id)}} style={{cursor:"pointer"}}>  
                            <img src={props.data.dp} class="rounded-circle shadow-sm" alt="profile" style={{height:80, width:80}}></img>
                        </div>
                        <div class="col">
                            <p style={{margin:0,fontWeight:600}} className='mb-3 sub-title'>{props.data.fullname}</p>
                            <h5 class="card-title sub-title mb-3"><MdOutlineCurrencyRupee className=""/> {props.data.amount}</h5>
                            <p class="card-text mb-3"><MdOutlineNotes className="me-2"/>{props.data.notes}</p>
                            <div className="row g-2 mb-3">
                                <div className="col">
                                    <p class="card-text"><MdOutlineDateRange className="me-2"/>{props.data.available_date}</p>
                                </div>
                                <div className="col">
                                    <p class="card-text"><MdAvTimer className="me-2"/>{props.data.available_time}</p>
                                </div>
                            </div>
                            {props.buttonVisible ? (<button 
                                class="btn btn-secondary px-4 w-100" 
                                onClick={()=>{props.onButtonClick(props.data.quotation_id)}}>
                                    Pay ₹{props.data.advance_amount} to book
                                    </button>) : <></>}
                        </div>
                    </div>
                </div>
            </div>):
            (<></>)}
            
        </div>
    )
}

export default Quotation