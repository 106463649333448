import status from "../helper/ColorMap";

function Timeline(props){
    return (
        <div>
            <span class="badge px-4 py-2" style={{backgroundColor:props.data.status_color, color:"#000", borderRadius:30, width:'auto'}}>{props.data.status_name}</span>
            <div class="row g-4">
                <div class="col-md-3 d-flex align-items-center justify-content-center">
                    <div class="connector-line" style={{width:3,height:"100%", backgroundColor:props.data.status_color}}></div>
                </div>
                <div class="col-md-9">
                    <p>{props.data.created_at}</p>
                    <p><b>{props.data.notes}</b></p>
                </div>
            </div>
        </div>
    )
}

export default Timeline;