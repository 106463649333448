import { Navigate, Outlet } from "react-router";

function ProtectedRoute(){
    var isLoggedIn = window.localStorage.getItem("token")!=null;
    console.log("ProtectedRoute", isLoggedIn);
    if(isLoggedIn){
        return(
            <Outlet/>
        )
    }
    else{
        return(
            <Navigate to="/login" replace/>
        );
        
    }
}

export default ProtectedRoute;