import { useEffect, useState } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useLocation } from "react-router-dom";
import Enquiry from "../components/Enquiry";
import Navbar from "../components/Navbar";
import Quotation from "../components/Quotation";
import { headers } from "../header";
import { Toaster } from "react-hot-toast";
import QrReader from 'react-qr-scanner'
import ProfessionalInfo from "../components/ProfessionalInfo";
import displayGateway from "../helper/RazorpayHelpers";
import notify from "../helper/toast";
import Timeline from "../components/Timeline";
import CompletionActionModal from "../components/Modal/CompletionActionModal";
import FinalAmountActionModel from "../components/Modal/FinalAmountActionModal";
import EntryActionModel from "../components/Modal/EntryActionModal";
import SelectedQuotation from "../components/SelectedQuotation";
import BackButton from "../components/Buttons/BackButton";
import AttachButton from "../components/Buttons/AttachButton";
import SupportButton from "../components/Buttons/SupportButton";
import { useFilePicker } from "use-file-picker";
import { useNavigate, useParams } from 'react-router';
import Spinner from "../components/Spinner";


function Info(){
    //Default state
    const navigate = useNavigate();
    const {state} = useLocation();
    const {enquiry_id} = useParams();
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [professionalInfo, setProfessionalInfo] = useState(null);
    const [professionalShow, setProfessionalShow] = useState(false);
    const [qrShow, setQrShow] = useState(false);
    const [completionModalShow, setCompletionModalShow] = useState(false);
    const [finalAmountModalShow, setFinalAmountModalShow] = useState(false);
    const [scanResult, setScanResult] = useState('');
    const [paymentRecievedModalShow, setPaymentRecievedModalShow] = useState(false);
    useEffect(()=>{
        console.log(state);
        getInfo();
    },[])

    const getProfessionalInfo = (id) => {
        setLoading(true);
        fetch(process.env.REACT_APP_BASE_URL+'shared/view-professional-profile?professional_id='+id,{
            method: "GET", 
            mode: 'cors',
            headers: headers}).then((res) => res.json()).then(data=>{
                setProfessionalInfo(data);
                setLoading(false);
                setProfessionalShow(true);
                console.log(data);
            })  
    }

    const getInfo = ()=>{
        setLoading(true);
        fetch(process.env.REACT_APP_BASE_URL+'user/view-enquiry?enquiry_id='+enquiry_id,{
        method: "GET", 
        mode: 'cors',
        headers: headers}).then((res) => res.json()).then(data=>{
            console.log(data);
            setData(data);
            if(data.enquiry.status === "5"){
                setCompletionModalShow(true);
            }
            else if(data.enquiry.status === "4" && data.selected_quotation.final_amount_accepted===false && data.selected_quotation.final_amount!=null){
                setFinalAmountModalShow(true);
            }
            setLoading(false);
        })
    }

    const acceptFinalAmount = ()=>{
        setLoading(true);
        setFinalAmountModalShow(false);
        fetch(process.env.REACT_APP_BASE_URL+'user/accept-final-amount?enquiry_id='+enquiry_id,{
        method: "POST", 
        mode: 'cors',
        headers: headers}).then((res) => res.json()).then(data=>{
            console.log(data);
            setLoading(false);
            getInfo();
            notify("Final amount accepted successfully");
        })
    }

    const rejectFinalAmount = ()=>{
        setLoading(true);
        setFinalAmountModalShow(false);
        fetch(process.env.REACT_APP_BASE_URL+'user/reject-final-amount?enquiry_id='+enquiry_id,{
        method: "POST", 
        mode: 'cors',
        headers: headers}).then((res) => res.json()).then(data=>{
            console.log(data);
            setLoading(false);
            getInfo();
            notify("Final amount rejected successfully");
        })
    }

    const acceptCompletion = ()=>{
        setLoading(true);
        setCompletionModalShow(false);
        fetch(process.env.REACT_APP_BASE_URL+'user/accept-completion?enquiry_id='+enquiry_id,{
        method: "POST", 
        mode: 'cors',
        headers: headers}).then((res) => res.json()).then(data=>{
            console.log(data);
            setLoading(false);
            getInfo();
            notify("Enquiry completed successfully");
        })
    }

    const rejectCompletion = ()=>{
        setLoading(true);
        setCompletionModalShow(false);
        fetch(process.env.REACT_APP_BASE_URL+'user/reject-completion?enquiry_id='+enquiry_id,{
        method: "POST", 
        mode: 'cors',
        headers: headers}).then((res) => res.json()).then(data=>{
            console.log(data);
            setLoading(false);
            getInfo();
            notify("Enquiry rejected successfully");
        })
    }


    const handlePayment = (id)=>{
        setLoading(true);
        fetch(process.env.REACT_APP_BASE_URL+"user/create-order", {
            method: "POST",
            headers: headers,
                body: JSON.stringify({
                    quotation_id: id,
                    })
                })
                .then((res) => res.json())
                .then((data) => {
                    console.log(data);
                    setLoading(false);
                    displayGateway(data.order_id, data.order_amount, onPaymentMade);
        })
    }

    const onPaymentMade = (response)=>{
        console.log(response);
        getInfo();
        setPaymentRecievedModalShow(true);
    }

    const handleAddEntry = ()=>{
        setLoading(true);
        setQrShow(false);
        fetch(process.env.REACT_APP_BASE_URL+'user/add-professional-entry',{
        method: "POST", 
        mode: 'cors',
        headers: headers,
        body:JSON.stringify({
            enquiry_id: enquiry_id,
            professional_id: scanResult
        })}).then(()=>{
            setLoading(false);
            getInfo();
            notify("Entry recorded successfully");
        })

    }

    const uploadImages = (e)=>{
        setLoading(true);
        const formData  = new FormData();
        formData.append('enquiry_id', enquiry_id);
        formData.append('image', e.target.files[0]);
        console.log(e.target.files);
        fetch(process.env.REACT_APP_BASE_URL+'user/upload-image',{
        method: "POST", 
        headers: {'Authorization': 'Bearer '+window.localStorage.getItem("token")},
        body:formData}).then(()=>{
            setLoading(false);
            getInfo();
            notify("Images uploaded successfully");
        })

    }

    const uploadImage = (file)=>{
        setLoading(true);
        const formData  = new FormData();
        formData.append('enquiry_id', enquiry_id);
        formData.append('image', file);
        fetch(process.env.REACT_APP_BASE_URL+'user/upload-image',{
        method: "POST", 
        headers: {'Authorization': 'Bearer '+window.localStorage.getItem("token")},
        body:formData}).then(()=>{
            setLoading(false);
            getInfo();
            notify("Images uploaded successfully");
        })

    }


    return(
        <div>
            <Toaster/>
            <Spinner visible={isLoading}/>
            <div>
                <div class="container">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <BackButton onClick={()=>{navigate(-1)}}/>
                        <SupportButton/>
                    </div>
                    
                    <div className="row">
                        <div className="col-12 d-flex justify-content-between align-items-center">
                            <p className="title">Enquiry info</p>
                            <AttachButton onImageSelected={(file)=>{uploadImage(file)}}/>
                        </div>
                    </div>
                    
                    
                    { Object.keys(data).length !== 0 ?(
                        <>
                            <Enquiry className="mb-3" data={data.enquiry} images={data.enquiry_images} buttonText="" classNames="mb-3"/>
                            {data.selected_quotation!=null?(
                                <div>
                                    <p className="mb-3 sub-title">Selected Quotation</p>
                                    <SelectedQuotation data={data.selected_quotation} className="mb-3" status={data.enquiry.status} onQrScan={()=>{setQrShow(true)}}/>
                                </div>
                            ):null}
                            <div>
                                <p className="mb-3 sub-title">Recieved quotations</p>
                                {data.received_quotations.map((quotation)=>{
                                    return(
                                        <div class="col-12">
                                            <Quotation data={quotation} buttonVisible={data.selected_quotation==null} onButtonClick={handlePayment} onProfessionalClick={getProfessionalInfo} buttonText="Accept"/>
                                        </div>
                                    )
                                })}
                            </div>
                            {data.enquiry.status>"2"?(
                                <div>
                                    <p className="mb-3 sub-title">Timeline</p>
                                    {data.timeline.map((timeline)=>{
                                        return(
                                            <Timeline data={timeline}/>
                                        )
                                    })}
                                </div>
                            ):null}
                            
                        </>
                    ):(<></>)}
                </div>
            </div>
            <paymentRecievedModalShow show={paymentRecievedModalShow} onHide={()=>{setPaymentRecievedModalShow(false)}}/>
            <ProfessionalInfo show={professionalShow} setShow={()=>{setProfessionalShow(false)}} data={professionalInfo}/>
            <CompletionActionModal isVisible={completionModalShow} onAccept={acceptCompletion} onReject={rejectCompletion}/>
            <FinalAmountActionModel isVisible={finalAmountModalShow} onAccept={acceptFinalAmount} onReject={rejectFinalAmount}/>

            <Offcanvas show={qrShow} placement="bottom" style={{height:"100%"}} onHide={()=>{setQrShow(false)}}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Scan QR Code</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="d-flex justify-content-center align-items-center">
                        <div>
                            <QrReader className="mb-3" delay={300} onError={(e)=>{console.log(e)}} onScan={(r)=>{r!== null? setScanResult(r.text): console.log("Null")}} style={{ width: '100%', height:"200px" }}/>
                            <input type="text" className="form-control mb-3" placeholder="Enter code manually" defaultValue={scanResult} onChange={(e)=>{setScanResult(e.target.value)}}/>
                            <button className="btn btn-primary px-4 w-100 mb-1" onClick={handleAddEntry}>Submit</button><br></br>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>

            
        </div>
    )
}

export default Info