import { Navbar } from "react-bootstrap";
import EnquiryGist from "../../components/EnquiryGist";
import Service from "../../components/Service";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router";
import { useState } from "react";
import { useEffect } from "react";
import { headers } from "../../header";
import Spinner from "../../components/Spinner";
function HomeFragment(){
    const navigate = useNavigate();
    const[isLoading, setLoading] = useState(false);
    const[enquiries, setEnquiries] = useState([]);
    const[services, setServices] = useState([]);
    const[banners, setBanners] = useState([]);

    useEffect(()=>{
        getServices();
        searchEnquiry();
        getBanners();
    },[])

    const searchEnquiry = ()=>{
        setLoading(true);
        fetch(process.env.REACT_APP_BASE_URL+'user/view-all-enquiries',{
        method: "GET", 
        mode: 'cors',
        headers: headers}).then((res) => res.json()).then(data=>{
            
                console.log(data.data);
                setEnquiries(data);
                setLoading(false);
            })
    }

    const getBanners = ()=>{
        fetch(process.env.REACT_APP_BASE_URL+'user/get-banners',{
        method: "GET", 
        mode: 'cors',
        headers:headers}).then((res) => res.json()).then(data=>{
            console.log(data);
                setBanners(data);
            })
    }

    const getServices = ()=>{
        setLoading(true);
        fetch(process.env.REACT_APP_BASE_URL+'data/view-services',{
        method: "GET", 
        mode: 'cors',
        headers:headers}).then((res) => res.json()).then(data=>{
            console.log(data);
                setServices(data);
                setLoading(false);
            })
    }

    const handleNavigation = (id)=>{
        navigate('/enquiry-info/'+id, {state: {id: id}});
    }
    
    return(
    <>
      <div>
        <Spinner visible={isLoading}/>
        <div class="container my-3 pb-5">
          <div class="row sticky">
            <Carousel showThumbs={false} showArrows={false} showIndicators={true} showStatus={false} className='mb-3'>
              {banners.map((banner)=>{
                return (
                  <div className='banner'>
                    <img src={banner.image} alt='tes'/>
                    <div className='content px-4'>
                      <p className='sub-title mb-0'>{banner.content}</p>
                      <a href={banner.link}>{banner.link_text}</a>
                    </div>
                  </div>
                )
              })}
              
            </Carousel>
            <p class="sub-title">Your enquiries</p>
            <div class="enquiries-container-horizontal">
              {enquiries.map((enquiry) => {
                return <EnquiryGist className="w-100" data={enquiry} onViewClick = {handleNavigation} buttonText="View info" show={true}/>
              })}

            </div>
            <div class="col-md-12">
              <p class="sub-title">Services we offer</p>
              <div className='row g-2 mb-3'>
                {services.map((service) => {
                  return (
                    <div className='col-4'>
                      <Service data={service} onClick={()=>{
                        navigate('/new-enquiry', {state: {service_id: service.service_id}});
                      }}/>
                    </div>
                  )
                })}
              </div>
            </div>
          </div> 
        </div> 
      </div>
    </>
    );
}
export default HomeFragment;