function Address(props){
    const bg = props.selected === props.data.address_id ? '#c0d2f6' : '#fff';
    return (
        <div class="card" style={{backgroundColor:bg, cursor:"pointer"}} onClick={()=>{props.onClick(props.data.address_id)}}>
            <div class="card-body">
                <h5 class="card-title">{props.data.address}</h5>
                <h6 class="card-subtitle mb-2 text-muted">{props.data.contact_person}</h6>
                <h6 class="card-subtitle mb-2 text-muted">{props.data.contact_phone}</h6>
                <p class="card-text">{props.data.pincode}</p>
            </div>
        </div>
    )
}

export default Address;