function Service(props){
    const bg = props.selected === props.data.service_id ? '#c0d2f6' : '#fff';
    return(
        <div className="service shadow-sm py-4 my-1 px-1" style={{backgroundColor:bg, cursor:"pointer"}} onClick={()=>{props.onClick(props.data.service_id)}}>
            <div className="row">
                <div className="col-md-12 d-flex justify-content-center align-items-center">
                    <img src={props.data.image} style={{height:50}}alt="service" className="img-fluid" />
                </div>
                <div className="col-md-12 d-flex justify-content-center align-items-center text-center">
                    <p className="mt-2"style={{margin:0}}>{props.data.title}</p>
                </div>
            </div>                
        </div>
    )
}

export default Service;