// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, onMessag,getToken } from "firebase/messaging";
import { AppRegistration } from "@mui/icons-material";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCW88OE8vP1r1JmRIamRo2gmkTlZnPH38c",
  authDomain: "homeradius-dc6c9.firebaseapp.com",
  projectId: "homeradius-dc6c9",
  storageBucket: "homeradius-dc6c9.appspot.com",
  messagingSenderId: "2050203085",
  appId: "1:2050203085:web:ebc05f2562d89663b1b884",
  measurementId: "G-K5WTKRM3VC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

export const getDeviceToken = (setTokenFound) => {
  return getToken(messaging, {vapidKey: 'BOswKrDKTlIsPW0U5Vp84vydmFyKu0OvOd9UnU_GpyaRt2dU_prExmIfq7FbGiw-MAeqY8TVkZX9WKI_s3WeepQ'}).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}
