import {MdOutlineSupportAgent} from "react-icons/md";
function SupportButton(){
    const trigger_phone_call = () => {
        window.location.href = 'tel:7418889868';
    };
    return(
        <div className="d-flex align-items-center justify-content-center px-4" onClick={trigger_phone_call}style={{backgroundColor:'#c0d2f6', width:'auto', borderRadius:'25px', height:'50px', fontWeight:'600', cursor:'pointer'}}>
            <MdOutlineSupportAgent className="me-2"/>
            <p className="m-0 p-0">Support</p>
        </div>
    )
}

export default SupportButton;