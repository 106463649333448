import '../assets/App.css';
import React, { useState } from "react";

function EnquiryGist(props){
    return (
        <div className='px-2'>
            {props.data!==null?(
                <div onClick={()=>{props.onViewClick(props.data.enquiry_id)}}>
                    <div class="enquiry-card mb-3 shadow-sm px-2 py-4">
                        <div className='row g-4'>
                            <div className='col-3'>
                                <img src={props.data.image} alt="..."  style={{width:'100%'}}/>
                            </div>
                            <div className='col-9'>
                                <div className='row'>
                                    <p class="sub-title">{props.data.enquiry}</p> 
                                    <p>{props.data.enquiry_id}</p>
                                    <span class="d-inline-block badge px-4 py-2" style={{backgroundColor:props.data.status_color, color:"#000", borderRadius:30, width:'auto'}}>{props.data.status_name}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (<></>)}
        </div>
        
    )
}

export default EnquiryGist;