import { useEffect, useState } from "react";
import Address from "../Address";
import { motion } from "framer-motion";

function AddressLayout(props){
    const[selectedAddress, setSelectedAddress] = useState('');
    useEffect(()=>{
        console.log(selectedAddress)
    },[selectedAddress])
    return(
        <motion.div
            initial={{ opacity: 0, x:-100}}
            animate={{ opacity: 1, x:0 }}
            exit={{ opacity: 0, x:-100 }}
            transition={{ duration: 0.5 }}
        >
            <div className="row g-4 mb-3">
                <p className="sub-title">Select an address</p>
                {props.addresses.map((address)=>{
                    return (
                        <div className="col-12">
                            <Address data={address} selected={selectedAddress} onClick={(id)=>{setSelectedAddress(id)}}/>
                        </div>
                    )
                })}
                <div className="col-12 col-sm-4" style={{cursor:"pointer"}} onClick={props.onAddAddress}>
                    <div class="card text-center">
                        <div class="card-body">
                            <p class="card-text">+ New Address</p>
                        </div>
                    </div>
                </div>

                <div className="d-flex w-100 justify-content-end mb-3">
                    <button className="btn btn-primary px-5" onClick={()=>{props.onNext(selectedAddress)}}>Book now</button>
                </div>
            </div>
        </motion.div>
        
    )
}

export default AddressLayout;