import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { useState } from "react";
import { MdHome, MdListAlt } from "react-icons/md";

function BottomNavigationComponent(props){
    const [value,setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
        props.onAction(value);
    };
    return(
        <BottomNavigation showLabels value={value} onChange={(e,val)=>{props.onAction(val);setValue(val)}} style={{position:'fixed',bottom:0,width:'100%'}}>
            <BottomNavigationAction label="Home" icon={<MdHome/>}/>
            <BottomNavigationAction label="All enquiries" icon={<MdListAlt />} />
        </BottomNavigation>
    )
}
export default BottomNavigationComponent;