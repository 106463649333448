import { useNavItem } from "@restart/ui/esm/NavItem";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { headers } from "../header";
import notify from "../helper/toast";
import LoadingOverlay from "react-loading-overlay";
import icon from '../assets/images/icon.png';
import { useEffect } from "react";

function Login(){

    const [phone, setPhone] = useState("");
    const [otp, setOtp] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [showOptions, setShowOptions] = useState(false);
    const [fullName, setFullName] = useState('');
    const [step, setStep] = useState(0);
    const [titleText, setTitleText] = useState('Enter mobile number to continue');
    const [subTitleText, setSubTitleText] = useState('Enter mobile number to Login or Signup');
    const [buttonText, setButtonText] = useState('Send OTP');
    

    useEffect(()=>{
        if(step === 0){
            setTitleText('Enter mobile number to continue');
            setSubTitleText('Enter mobile number to Login or Signup');
            setButtonText('Send OTP');
        }
        else if(step === 1){
            setTitleText('Create an account to continue');
            setSubTitleText('Enter your full name below');
            setButtonText('Create account');
        }
        else{
            setTitleText('Enter OTP to continue');
            setSubTitleText('Enter OTP to Login');
            setButtonText('Login');
        }
    }, [step])



    const handleLogin = () => {
        setLoading(true);
        if(step === 0){
            console.log(phone);
            fetch(process.env.REACT_APP_BASE_URL+"auth/check-account", {
                method: "POST",
                headers: headers,
                    body: JSON.stringify({
                        phone: phone,
                        type:"USR"
                        })
                    })
                    .then((data) => data.json())
                    .then((data)=>{
                        setLoading(false);
                        console.log(data)
                        if(data.found === true){
                            setStep(2);
                        }
                        else{
                            setStep(1);
                        }
                        setShowOptions(true);
                    })
            
        }
        else if(step===1){

            setLoading(true);
            console.log(phone);
            fetch(process.env.REACT_APP_BASE_URL+"auth/create-user", {
                method: "POST",
                headers: headers,
                    body: JSON.stringify({
                        phone: phone,
                        fullname:fullName
                        })
                    })
                    .then((data) => {
                        setLoading(false);
                        if(data.status !== 200){
                            notify("User alreary exists, please login");
                        }
                        else{
                            notify("Account created successfully :), Enter OTP to continue");
                            setStep(2);
                    }
            })
            
        }
        else{
            fetch(process.env.REACT_APP_BASE_URL+"auth/verify-otp", {
                method: "POST",
                headers: headers,
                    body: JSON.stringify({
                        phone: phone,
                        otp:otp,
                        type:"USR"
                        })
                    })
                    .then((res) => res.json())
                    .then((data) => {
                        console.log(data);
                        setLoading(false);
                        if(data.status === "error"){
                            setError(data.error);
                        }
                        else{
                            notify("Welome back !");
                            window.localStorage.setItem("token", data.access_token);
                            window.location.href = "/";
                            console.log(data);
                        }
                        
            })
        }
        
    }
    
    return (
        <div>
            <div className="auth-cover">
                <img src={icon} alt="logo" ></img>
            </div>
            <div className="container">
                <Toaster />
                <LoadingOverlay active={loading} spinner>
                
                    <div class="mt-5">
                        <p className="title mb-1">{titleText}</p>
                        <p className="mt-0 pt-0">{subTitleText}</p>
                        <div class="col-md-12 mb-3">
                            <input type="number" inputmode="numeric" class="form-control disabled" placeholder="Mobile number" disabled={step>0} onChange={(e)=>{setPhone(e.target.value)}} style={step>0?{backgroundColor:'#fafafa'}:{}}/>
                        </div>

                        {showOptions && step ===2? 
                            (<div class="col-md-12 mb-3">
                                <input type="number" inputmode="numeric" class="form-control" placeholder="Enter OTP" onChange={(e)=>{setOtp(e.target.value)}}/>
                            </div>) : null
                        }
                        {showOptions && step===1?
                            (<div class="col-md-12 mb-3">
                                <input type="text" class="form-control" placeholder="Full name" onChange={(e)=>{setFullName(e.target.value)}}/>
                            </div>) : null
                        }
                        <div className='d-flex w-100 justify-content-end'>
                            <button class="btn btn-primary mb-3 px-5" onClick={handleLogin}>{buttonText}</button>
                        </div>
                    </div>
                </LoadingOverlay>
            </div>
        </div>
        
    );
}
export default Login;