import { IoMdArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router';
function BackButton(props){
    
    const back = () => {
        props.onClick();
    }
    return (
        <div className="back-button my-4" onClick={back}> 
            <IoMdArrowBack/> 
        </div>
    )
}

export default BackButton;