import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import LoadingOverlay from "react-loading-overlay";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { headers } from "../../header";
import notify from "../../helper/toast";
import AddressLayout from "./AddressLayout";
import DateLayout from "./DateLayout";
import DescriptionLayout from "./DescripionLayout";
import ServicesLayout from "./ServicesLayout";
import TimeLayout from "./TimeLayout";
import Success from "../../pages/Success";
import DateTimeLayout from "./DateTimeLayout";
import FieldLayout from "./FieldLayout";

function PageHadler(props){
    useEffect(()=>{
        getServices();
        getAddresses();
    },[]);
    const [services, setServices] = useState([]);
    const [addresses,setAddresses] = useState([]);
    const [loading,setLoading] = useState(false);
    const[enquirySuccess,setEnquirySuccess] = useState(false);

    const[selectedService, setSelectedService] = useState(props.selectedService);
    const[selectedDescription, setSelectedDescription] = useState('');
    const[selectedDate,setSelectedDate] = useState('');
    const[selectedTime, setSelectedTime] = useState('');
    const[fields, setFields] = useState([]);
    const[selectedAddress,setSelectedAddress] = useState('');

    const[addressSheetVisible, setAddressSheetVisible] = useState(false); 
    const[address,setAddress] = useState('');
    const[contactPerson,setContactPerson] = useState('');
    const[contactPhone,setContactPhone] = useState('');
    const[pincode,setPincode] = useState('');
    

    const getServices = ()=>{
        setLoading(true);
        fetch(process.env.REACT_APP_BASE_URL+'data/view-services',{
            method: "GET", 
            mode: 'cors',
            headers: headers}).then((res) => res.json()).then(data=>{
            console.log(data);
                setServices(data);
                setLoading(false);
        })
    }

    const getAddresses = ()=>{
        setLoading(true);
        fetch(process.env.REACT_APP_BASE_URL+'user/view-addresses',{
            method: "GET", 
            mode: 'cors',
            headers: headers}).then((res) => res.json()).then(data=>{
            console.log(data);
                setAddresses(data);
                setLoading(false);
        })
    }

    const createEnquiry=(address)=>{
        if(selectedDescription !== '' && selectedDate !== '' && selectedTime !== '' && selectedService !== '' && address !== ''){
            setLoading(true);
            fetch(process.env.REACT_APP_BASE_URL+"user/create-enquiry", {
                method: "POST",
                headers: headers,
                    body: JSON.stringify({
                        requested_date: selectedDate,
                        requested_time:selectedTime, 
                        enquiry:selectedDescription,
                        address_id:address,
                        service_id:selectedService
                        })
                    })
                    .then((data) => {
                        notify("Enquiry created successfully");
                        setLoading(false);
                        setEnquirySuccess(true);
            })
        }
        else{
            notify("Please fill all the fields");
        }
        
    }

    const createAddress = ()=>{
        if(address !== '' && contactPerson !== '' && contactPhone !== '' && pincode !== ''){
            setLoading(true);
            fetch(process.env.REACT_APP_BASE_URL+"user/add-address", {
                method: "POST",
                headers: headers,
                    body: JSON.stringify({
                        contact_person: contactPerson,
                        contact_phone:contactPhone, 
                        address:address,
                        pincode:pincode
                        })
                    })
                    .then((data) => {
                        notify("Address added successfully");
                        setLoading(false);
                        setAddressSheetVisible(false);
                        getAddresses();
            })
        }
        else{
            notify("Please fill all the fields");
        }
    }

    const handleNext = (content)=>{
        console.log(content);
        console.log(props.currentPage)
        if(content!==undefined && content!=='' && content!==null
            && content!==[] && content!=={}){
            if(props.currentPage===1){
                setSelectedDescription(content);
                console.log(content);
            }
            else if(props.currentPage===2){
                if(content.date==='' || content.time===''){
                    notify("Please select date and time");
                    return;
                }
                setSelectedDate(content.date);
                setSelectedTime(content.time);
                console.log(content);
            }
            else if(props.currentPage===3){
                console.log("selected address")
                setSelectedAddress(content);
                createEnquiry(content);
                return;
            }
            props.handleNext();
        }
        else{
            notify("This field is required");
        }
    }

    return (
        <>
        <Toaster/>
            <LoadingOverlay active={loading} spinner>
                {props.currentPage===1 &&!enquirySuccess ? <DescriptionLayout selectedService={services.find((it)=>{return it.service_id===selectedService})} selectedDescription={selectedDescription} onNext={handleNext}/>:<></>}
                {props.currentPage===2 &&!enquirySuccess? <DateTimeLayout selectedDate={selectedDate} selectedTime={selectedTime} onNext={handleNext}/>:<></>}
                {/* {props.currentPage===3 &&!enquirySuccess? <FieldLayout fields={fields}  onNext={handleNext}/>:<></>} */}
                {props.currentPage===3 &&!enquirySuccess? <AddressLayout addresses={addresses} selectedAddress={selectedAddress} onNext={handleNext} onAddAddress={()=>{setAddressSheetVisible(true)}}/>:<></>}
                {enquirySuccess ? <Success/>:<></>}

                <Offcanvas show={addressSheetVisible} onHide={()=>{setAddressSheetVisible(false)}}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Add new address</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <textarea type="text" className="form-control mb-3" placeholder="Address" onChange={(e)=>{setAddress(e.target.value)}}/>
                        <input type="number" className="form-control mb-3" placeholder="Pincode" onChange={(e)=>{setPincode(e.target.value)}}/>
                        <input type="text" className="form-control mb-3" placeholder="Contact person" onChange={(e)=>{setContactPerson(e.target.value)}}/>
                        <input type="phone" className="form-control mb-3" placeholder="Contact phone" onChange={(e)=>{setContactPhone(e.target.value)}}/>
                        <button class="btn btn-primary w-100 mt-3" onClick={createAddress}>Add address</button>
                    </Offcanvas.Body>
                </Offcanvas>

            </LoadingOverlay>
        </>
        
    )
}

export const slideVariants = {
    initial: { x: "-100%" },
    animate: { x: 0 },
    exit: { x: "100%" }
  };

export default PageHadler;