import React from "react";
import ReactStars from "react-rating-stars-component";

function  Review(props){
    return(
        <div>
            <div className="row">
                <div className="col-3 d-flex justify-content-center align-items-top">
                    <img src={props.data.dp} style={{height:70,width:70,borderRadius:35,objectFit:"cover"}} alt="profile"></img>
                </div>
                <div className="col-9">
                    <p className="sub-title m-0">{props.data.fullname}</p>
                    <ReactStars
                        count={5}
                        size={24}
                        value={props.data.rating}
                        activeColor="#ffd700"
                        edit={false}
                    />
                    <p>{props.data.comment}</p>
                </div>
            </div>
        </div>

    )
}

export default Review;