const displayGateway = async function displayRazorpay(order_id, amount, handler) {
    console.log("displayGateway");
    const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
    }

    const options = {
        key: "rzp_live_M512ManP57vJzq", // Enter the Key ID generated from the Dashboard
        amount: amount.toString(),
        currency: "INR",
        name: "Homeradius",
        image: "https://homeradius.in/assets/brand/icon.png",
        order_id: order_id,
        handler: async function (response) {
            console.log(response);
            handler();
        },
        theme: {
            color: "#212529",
        },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
}

function loadScript(src) {
    console.log("loadScript");
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

export default displayGateway;