import '../assets/App.css';
import React, { useState } from "react";
import { BsCalendarDate,BsClock,BsGeo,BsReceipt,BsPersonBoundingBox } from "react-icons/bs";
import status from '../helper/ColorMap';

function Enquiry(props){
    return (
        <div className='container my-2'>
            {props.data!==null?(
                <div>
                <div class="enquiry-card mb-3">
                    <div className='d-flex my-2'>
                        <span class="d-inline-block badge px-4 py-2 me-2" style={{backgroundColor:props.data.status_color, color:"#000", borderRadius:30, width:'auto'}}>{props.data.status_name}</span>
                        <span class="d-inline-block badge px-4 py-2 ms-2" style={{backgroundColor:"#fff", color:"#000", borderRadius:30}}>{props.data.enquiry_id}</span>
                    </div>
                    <p class="sub-title my-4">{props.data.enquiry}</p> 
                    <div className='row mb-3'>
                        <div className='col-3 d-flex align-items-center'>
                            <img src={props.data.image} alt="..."  className='w-100'/>
                        </div>
                        <div className='col-9'>
                            <div className='row g-4'>
                                <div className='col-md-6'>
                                    <p class="card-text small ">
                                        <BsCalendarDate/> {props.data.requested_date}
                                    </p>
                                </div>
                                <div className='col-md-6'>
                                    <p class="card-text small ">
                                        <BsClock/> {props.data.requested_time}
                                    </p>
                                </div>
                                <div className='col-md-12'>
                                    <p class="card-text small ">
                                        <BsGeo/> {props.data.address}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row g-4'>
                        {props.images.map((item)=>{
                            return (
                                <div className='col-4'>
                                    <img src={item.image_url} alt="..."  className='w-100' style={{objectFit:"cover", height:"100px"}}/>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            ) : (<></>)}
        </div>
        
    )
}

export default Enquiry;