import { useState } from "react";
import { headers } from "../../header";
import EnquiryGist from "../../components/EnquiryGist";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import Spinner from "../../components/Spinner";

function EnquiriesFragment(){
    const[enquiries, setEnquiries] = useState([]);
    const[isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(()=>{
        getEnquiries();
      },[])
    const getEnquiries = ()=>{
        setLoading(true);
        fetch(process.env.REACT_APP_BASE_URL+'user/view-all-enquiries',{
        method: "GET", 
        mode: 'cors',
        headers: headers}).then((res) => res.json())
        .then(data=>{
                console.log(data.data);
                setEnquiries(data);
                setLoading(false);
            })
    }
    const handleNavigation = (id)=>{
        navigate('/enquiry-info/'+id, {state: {id: id}});
    }
    return(
        <>
            <Spinner visible={isLoading}/>
            <div class="container my-3 pb-5">
                <p class="sub-title">Your enquiries</p>
                {enquiries.map((enquiry) => {
                    return <EnquiryGist data={enquiry} onViewClick = {handleNavigation} buttonText="View info" show={true}/>
                })}
            </div>
        
        </>
    );
}
export default EnquiriesFragment;