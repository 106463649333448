import { useNavigate } from "react-router-dom";
import "../../node_modules/jquery/dist/jquery.min.js";
import "../../node_modules/bootstrap/dist/js/bootstrap.min.js";
function Navbar(){
  const navigate = useNavigate();
  const handleLogout = ()=>{
    window.sessionStorage.removeItem("token");
    window.location.href = "/";
  }
    return(
      <nav class="navbar navbar-expand-lg bg-hr sticky-top">
      <div class="container ">
            <div>
              <img src="https://www.homeradius.in/assets/brand/logo-full.png" alt="logo" style={{height:30, width:'auto'}}/>
            </div>
            <div>
              <img src="https://www.pngall.com/wp-content/uploads/5/Profile-PNG-File.png" alt="logo" style={{height:50, width:50}}/>
            </div>
      </div>
    </nav>
    )
}

export default Navbar