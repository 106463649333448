import '../assets/App.css';
import React, { useEffect, useState } from "react";
import Navbar from '../components/Navbar';
import { Outlet, useNavigate } from 'react-router-dom';
import { headers } from '../header';
import EnquiryGist from '../components/EnquiryGist';
import Service from '../components/Service';
import BottomNavigationComponent from '../components/Navigation/BottomNavigationComponent';
import { getDeviceToken } from '../helper/Firebase';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


function Home() {
  const navigate = useNavigate();
  const [pageState, setPageState] = useState(0);
  useEffect(()=>{
    if(pageState === 0){
      navigate('/home/all');
    }
    else if(pageState === 1){
      navigate('/home/enquiries');
    }
  },[pageState]);
  return (
    <>
      <Navbar/>
      <Outlet/>
      <BottomNavigationComponent onAction={(state)=>{setPageState(state)}}/>
    </>
  );
}

export default Home;
