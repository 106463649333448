import { BsCalendarDate,BsClock} from "react-icons/bs";
import { MdNotes } from "react-icons/md";
import { AiOutlineScan } from "react-icons/ai";
function SelectedQuotation(props){
    console.log("Status");
    console.log(props.data);
    return (
        <div>
            {props.data!==null?(
            <div class="card bg-hr p-3 border-none mb-3" style={{background:"#212529", color:"#fff"}}>
                <div class="card-body">
                    <div class="row g-4 d-flex mb-3" onClick={()=>{props.onProfessionalClick(props.data.professional_id)}} style={{cursor:"pointer"}}>
                        <div class="col-auto">  
                            <img src={props.data.dp} class="rounded-circle shadow-sm" alt="profile" style={{height:60, width:60}}></img>
                        </div>
                        <div class="col">
                            <p style={{margin:0,fontWeight:600}} className='mb-3'>{props.data.fullname}</p>
                            <h5 class="card-title mb-3">₹ {props.data.amount}</h5>
                            <h5 class="card-title mb-3">₹ {props.data.final_amount} final amount</h5>
                            <p class="card-text"><MdNotes/>{props.data.notes}</p>
                            <div className='row g-2 mb-3'>
                                <div className='col-6'>
                                    <p class="card-text small"><BsCalendarDate/> {props.data.available_date}</p>
                                </div>
                                <div className='col-6'>
                                    <p class="card-text small"><BsClock/> {props.data.available_time}</p>
                                </div>
                            </div>
                            {props.status==='3'?(<button className="btn btn-secondary w-100 my-2" onClick={()=>{props.onQrScan()}}><AiOutlineScan/>&nbsp;Record entry</button>):null}
                            <a className="btn btn-secondary w-100 my-2" href={"tel:"+props.data.phone}>Call now</a>
                        </div>
                    </div>
                </div>
            </div>):
            (<></>)}
            
        </div>
    )
}

export default SelectedQuotation;