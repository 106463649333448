import { motion } from "framer-motion";

function CompletionActionModal(props){
    return (
        <>      
            {
                props.isVisible?(
                    <motion.div
                        initial={{ opacity: 0, Y:-100}}
                        animate={{ opacity: 1, Y:0 }}
                        exit={{ opacity: 0, Y:-100 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className="modal-backdrop">
                            <div className="modal-container">
                                <div className="row g-4">
                                    <div className="col-12">
                                        <p className="title">Professional has requested completion of the order</p>
                                    </div>
                                    <div className="col-12">
                                        <p className="sub-title">Bill splitup</p>
                                        <div className="row g-2">
                                            <div className="col-6">
                                                <p className="">Finalised amount</p>
                                            </div>
                                            <div className="col-6 d-flex justify-content-center">
                                                <p className="">100</p>
                                            </div>
                                            <div className="col-6">
                                                <p className="">Finalised amount</p>
                                            </div>
                                            <div className="col-6 d-flex justify-content-center">
                                                <p className="">100</p>
                                            </div>
                                            <div className="col-6">
                                                <p className="">Finalised amount</p>
                                            </div>
                                            <div className="col-6 d-flex justify-content-center">
                                                <p className="">100</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <button className="btn btn-primary w-100" onClick={props.onReject}>Reject</button>
                                    </div>
                                    <div className="col-6">
                                        <button className="btn btn-secondary w-100" onClick={props.onAccept}>Accept</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                ):null
            }
        </>
        
    )
}
export default CompletionActionModal;