import { LottiePlayer, useLottie } from "lottie-react";
import { useNavigate } from "react-router";
import success_lottie from "../assets/lottie/success.json";

function Success(){
    const navigate = useNavigate();
    const options = {
        animationData: success_lottie,
        loop: false
      };
    
      const { View } = useLottie(options);
    return(
        <div className="container d-flex align-items-center justify-content-center h-100" style={{height:'100vh'}}>
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col-6 m-5">
                    {View}
                </div>
                <div className="col-12 d-flex justify-content-center align-items-center">
                    <h5>Enquiry posted</h5>
                </div>
                <div className="col-12 mb-3 d-flex justify-content-center align-items-center text-center">
                    <p>You will recieve estimations from our professionals within 10 minutes </p>
                </div>
                <div className="col-12">
                    <button className="btn btn-primary w-100" onClick={()=>{navigate('/')}}>Close</button>
                </div>
            </div>
            
        </div>
    )
}

export default Success;