import { useEffect, useState } from "react";
import {MdAttachFile} from "react-icons/md";
import { useFilePicker } from 'use-file-picker';
import { headers } from "../../header";

function AttchButton(props){
    const [isLoading, setLoading] = useState(false);
    const [openFileSelector, { filesContent, loading, errors }] = useFilePicker({
        readAs: 'DataURL',
        accept: 'image/*',
        multiple: true,
        onFilesSuccessfulySelected: ({ plainFiles, filesContent }) => {
          // this callback is called when there were no validation errors
          console.log('onFilesSuccessfulySelected', plainFiles[0]);
         // uploadImage(plainFiles[0]);
          props.onImageSelected(plainFiles[0]);
        },
      });

     
    
    return(
        <div className="d-flex align-items-center" style={{fontWeight:'600', color:'#0645AD', cursor:'pointer'}} onClick={openFileSelector}>
            <MdAttachFile className="me-2"/>
            <p className="m-0 p-0">Attach photos</p>
        </div>
    )
}

export default AttchButton;