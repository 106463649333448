import './assets/App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { motion } from "framer-motion";
import Login from './pages/Login';
import Signup from './pages/Signup';
import Info from './pages/Info';
import NewEnquiry from './pages/NewEnquiry';
import Success from './pages/Success';
import Home from './pages/Home';
import ProtectedRoute from './helper/ProtectedRoute';
import HomeFragment from './pages/Fragments/HomeFragment';
import EnquiriesFragment from './pages/Fragments/EnquiriesFragment';


function App() {
  return(
    <motion.div
            initial={{ opacity: 0}}
            animate={{ opacity: 1}}
            exit={{ opacity: 0}}
            transition={{ duration: 0.5 }}
        >
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login/>} />
            <Route path="/signup" element={<Signup/>} />
            <Route element={<ProtectedRoute/>}>
              <Route path="/" element={<Home/>} />
              <Route path="/home" element={<Home/>}>
                <Route path="all" element={<HomeFragment/>} />
                <Route path="enquiries" element={<EnquiriesFragment/>} />
              </Route>
              <Route path="/enquiry-info/:enquiry_id" element={<Info />} />
              <Route path="/new-enquiry" element={<NewEnquiry />} />
              <Route path="/success" element={<Success />} />
            </Route>
          </Routes>
        </BrowserRouter>
       
    </motion.div>
  );
}

export default App;
